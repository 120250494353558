@import '../../variables.scss';
@import '../../mixins.scss';

.b-dropdown {
  position: relative;

  .e-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);
    background-color: $white-alt;
    border-radius: 5px;
    padding: 8px $padding-small;
    cursor: pointer;
  }

  .e-arrow {
    width: 24px;
    height: 24px;
    transition: transform ease 0.3s;

    &.m-open {
      transform: rotate(180deg);
    }
  }

  .e-list {
    position: absolute;
    left: -99999px;
    top: calc(100% + 8px);
    right: 99999px;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform ease 0.3s, opacity ease 0.3s;
    background-color: $white;
    border-radius: 5px;
    z-index: $dropdownZIndex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    @include mobile-scroll();
    max-height: 200px;

    &.m-open {
      left: 0;
      right: 0;
      transform: translateY(0);
      opacity: 1;
    }
  }

  .e-item {
    position: relative;
    padding: $padding-small;
    background-color: $white-alt;
    transition: background-color ease 0.3s;
    cursor: pointer;

    &:hover,
    &.m-active {
      background-color: rgba($grey-alt, 0.7);
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: $padding-small;
      right: $padding-small;
      height: 1px;
      background-color: $grey-alt;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }
}