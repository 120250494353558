@import '../../variables.scss';

.b-invite-page {
    svg {
        max-width: 400px;
    }

    h1 {
        @media (max-width: $tablet-breakpoint - 1) {
            text-align: center;
            font-size: 18px;
            margin-bottom: $padding-small;
        }

        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }

    .e-content {
        padding: 0 $padding-small;
    }

    .e-user {
        display: flex;
        align-items: center;
    }

    .e-user-avatar {
        @media (max-width: $tablet-breakpoint - 1) {
            display: none;
        }
    }

    .e-user-info {
        margin-left: $padding-small;

        @media (max-width: $tablet-breakpoint - 1) {
            margin: 0 auto $padding-small;
        }

        p {
            margin: 4px 0;

            &:first-child {
                margin-top: 0;
                font-weight: 700;
            }

            &:last-child {
                margin-bottom: 0;
            }

            @media (max-width: $tablet-breakpoint - 1) {
                display: inline-block;
                margin-bottom: 0;

                &:first-child {
                    font-weight: 400;

                    &:after {
                        content: '\00a0'; // Add a space after the first paragraph
                    }
                }
            }
        }

    }

    .e-invite-container {
        max-width: 480px;
    }

    .e-group {
        display: flex;
        align-items: center;
        margin-top: $padding-base;

        @media (max-width: $tablet-breakpoint - 1) {
            margin-top: 0;
            flex-direction: column;
        }

        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }

    .e-group-info {
        margin-right: $padding-small;

        @media (max-width: $tablet-breakpoint - 1) {
            h2 {
                font-size: 24px;
            }
        }
    }

    .e-group-members {
        display: flex;
        align-items: center;
        flex-direction: row;

        >span {
            display: inline-block;
            margin-right: 19px;
        }

        @media (max-width: $tablet-breakpoint - 1) {
            display: none;
        }
    }

    .e-purpose {
        margin: $padding-base 0;
        font-size: 24px;

        @media (max-width: $tablet-breakpoint - 1) {
            text-align: center;
        }

        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
            font-size: 34px;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }

    .e-invite-actions {
        p {
            margin-bottom: 0;
        }
    }

    .e-web-btn {
        margin-top: 27px;
    }

    .e-app-links {
        display: flex;
        justify-content: center;
        max-width: 260px;
        width: 100%;
        margin: 0 auto;

        &.m-center {
            margin: 0 auto;
        }

        @media (min-width: $tablet-breakpoint) {
            margin: 0;
            justify-content: space-between;
        }
    }

    .e-signup-links {
        margin: $padding-base 0;
    }

    .e-signup-link {
        text-decoration: none;
        color: $white;
        padding-left: 18px;
        border-left: 1px solid $white;
        font-size: 22px;

        &:first-child {
            padding: 0 18px 0 0;
            border: none;
        }
    }

    .e-center {
        text-align: center;

        @media (min-width: $tablet-breakpoint) {
            text-align: left;
        }
    }
}