@import '../../variables.scss';

.b-profile-settings-image {
  position: relative;
  text-align: center;

  .e-avatar {
    display: inline-block;
    margin-bottom: $padding-small;
  }

  .e-remove-image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: $padding-base;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    img {
      vertical-align: middle;
      margin-left: $padding-small;
    }
  }
}