@import '../../variables.scss';

.b-pill-list-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        left: -40px;
        bottom: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(180deg,
                rgba($white, 0) 0%,
                rgba($white, 0) 50%,
                rgba($white, 1) 100%);
    }

    &:after {
        display: none;
    }

    @media (min-width: $tablet-breakpoint) {
        display: flex;

        &:after {
            display: block;
        }
    }
}