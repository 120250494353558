@mixin text-ellipse {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin hide-mobile {
    @media (max-width: $tablet-breakpoint - 1) {
        display: none;
    }
}

@mixin hide-tablet {
    @media (min-width: $tablet-breakpoint) {
        display: none;
    }
}

@mixin hide-desktop {
    @media (min-width: $desktop-breakpoint) {
        display: none;
    }
}

@mixin box-shadow {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

@mixin mobile-scroll {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}