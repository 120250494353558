@import "../../variables.scss";
@import "../../mixins.scss";

.b-bank-details {
  .e-content {
    margin: 0 auto;
    max-width: 320px;
  }

  h1 {
    font-size: 32px;
    line-height: normal;
    margin-bottom: $padding-small;
  }

  p {
    font-size: 16px;
  }

  .e-input-field {
    margin-bottom: $padding-small;

    .e-input {
      transition: background-color ease 0.3s;
      background-color: $grey-field;
    }
  }
}

.b-profile-settings {
  position: fixed;
  top: 0;
  left: -100%;
  width: $menuWidth;
  bottom: 0;
  transition: transform ease 0.3s;
  background-color: $white;
  z-index: 4;

  @media (max-width: $mobile-small-breakpoint) {
    width: $menuWidthXS;
  }

  @media (min-width: $tablet-breakpoint) {
    left: 0;
    transform: translateX(-100%);
  }

  &.m-editing {
    background-color: $very-light-link;
  }

  .e-profile-setting-scroll {
    @include mobile-scroll();
    height: calc(100vh - 124px);
  }

  .e-profile-back {
    margin: -20px (-$padding-base) (-$padding-base);
    padding: $padding-base;
  }

  .e-profile-editing-back {
    margin: -28px (-$padding-base) (-$padding-base);
    padding: $padding-base;
  }

  .e-profile-avatar {
    background-color: $pale-teal;
    padding: $padding-base;
  }

  .e-profile-details {
    padding: $padding-base;
  }

  .e-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    padding-left: $padding-small;
  }

  &.m-active {
    transform: translateX(100%);
    background-color: $grey-tile;

    @media (min-width: $tablet-breakpoint) {
      transform: translateX(0);
    }
  }

  .e-settings-edit {
    position: absolute;
    top: 0;
    right: 0;
    padding: $padding-base;
    cursor: pointer;
  }

  .e-user-email {
    text-align: center;
    margin-top: 36px - $padding-base;
    color: $grey;
  }
}

.b-list-search {
  display: flex;
  height: 52px;
  padding-bottom: 0px;
  align-items: center;

  @media all and (min-width: $tablet-breakpoint) {
    padding: 1.5rem;
    padding-bottom: 0;
  }

  .e-list-search-input {
    position: relative;
    flex-grow: 1;
    &::before {
      content: "";
      display: block;
      position: absolute;
      background: url("../../images/search.svg") no-repeat center;
      height: 2rem;
      width: 2.5rem;
    }
    input {
      border: none;
      border-radius: 5px;
      background-color: $white;
      font-size: 14px;
      width: 100%;
      padding: 8px 16px 8px 32px;
      box-sizing: border-box;
    }

    &.m-list-search-input-grey input {
      background-color: $grey-field;
    }
  }

  .btn-cancel {
    display: inline-block;
    border: none;
    padding: 0 0 0 0.75rem;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: $pinkish-tan;
    font-family: inherit;
    font-size: 0.875rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 1rem;
    line-height: 1rem;
  }
}
