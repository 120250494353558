@import 'variables.scss';

html,
body {
    font-family: "Cerebri Sans", sans-serif;
}

h1,
.g-h1 {
    font-weight: 800;
    font-size: 32px;
    margin: 0 0 16px;

    @media (min-width: $tablet-breakpoint) {
        font-size: 44px;
    }

    &.m-regular {
        font-weight: 400;
        font-size: 28px;

        @media (min-width: $tablet-breakpoint) {
            font-size: 36px;
        }
    }
}

h2,
.g-h2 {
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 16px;

    &.m-big {
        font-weight: 800;
        font-size: 24px;

        @media (min-width: $tablet-breakpoint) {
            font-size: 36px;
        }
    }
}

.g-cta {
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 16px;

    &.m-small {
        font-size: 16px;
    }
}

h4,
.g-h4 {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 16px;

    &.m-title {
        color: $grey;
    }
}

h5,
.g-h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 16px;

    &.m-regular {
        font-weight: 400;
    }
}

p,
.g-p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 16px;
}

a,
.g-a {
    text-decoration: underline;
    color: inherit;
    cursor: pointer;

    &.m-no-underline {
        text-decoration: none;
    }
}