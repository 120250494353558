@import 'variables.scss';
@import 'mixins.scss';

iframe#launcher,
iframe#webWidget {
    @media (min-width: $tablet-breakpoint + 1) {
        z-index: $zendeskZIndex !important;
    }
}

.g-hide-widget {
    iframe#launcher {
        bottom: -200px !important;
    }
}

* {
    box-sizing: border-box;
}

.g-container {
    max-width: 960px;
    margin: 0 auto;

    &.m-scroll-auto {
        @include mobile-scroll();
        height: 100%;
    }

    &.m-padding-bottom {
        padding-bottom: $padding-base;
    }

    &.m-no-max-width {
        max-width: none;
    }
}

.g-transition {

    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 1s;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity 1s;
        }
    }
}

.g-box-shadow {
    @include box-shadow();
}

.g-z-index-1 {
    z-index: 1;
}

.g-touch-scroll {
    @include mobile-scroll();
}

.g-text-center-mobile {
    @media (max-width: $tablet-breakpoint - 1) {
        text-align: center;
    }
}

.g-text-center {
    text-align: center;
}

.g-text-primary {
    color: $pinkish-tan !important;
}

.g-hide-mobile {
    @media (max-width: $tablet-breakpoint - 1) {
        display: none;
    }
}

.g-hide-tablet {
    @media (min-width: $tablet-breakpoint) {
        display: none;
    }
}

.g-hide-desktop {
    @media (min-width: $desktop-breakpoint) {
        display: none;
    }
}

.g-fade {
    opacity: 0;
    transition: opacity ease 1s;

    &.m-in {
        opacity: 1;
    }
}

.g-loader-gradient {
    position: relative;
    width: auto;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: -100%;
        bottom: -100%;
        left: -#{$gradientWidth};
        width: $gradientWidth;
        transform: rotate(20deg);
        background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 50%, rgba($white, 0) 100%);
        z-index: 1;
        animation: gradientAnim 1.5s ease-out infinite;

        @keyframes gradientAnim {
            from {
                left: -#{$gradientWidth};
            }

            to {
                left: 100%;
            }
        }
    }
}

.g-loader {
    background-color: $brown-grey;
    border-radius: 5px;
    opacity: 0.2;

    &.m-round {
        border-radius: 50%;
    }

    &.m-btn {
        border-radius: 30px;
        height: 48px;
    }
}

.g-margin {
    &-vertical {
        margin-top: $padding-small;
        margin-bottom: $padding-small;

        &-lg {
            margin-top: $padding-base;
            margin-bottom: $padding-base;
        }
    }

    &-horizontal {
        margin-left: $padding-small;
        margin-right: $padding-small;

        &-lg {
            margin-left: $padding-base;
            margin-right: $padding-base;
        }
    }

    &-top {
        margin-top: $padding-small;

        &-lg {
            margin-top: $padding-base;
        }
    }

    &-right {
        margin-right: $padding-small;

        &-lg {
            margin-right: $padding-base;
        }
    }

    &-bottom {
        margin-bottom: $padding-small;

        &-lg {
            margin-bottom: $padding-base;
        }
    }

    &-left {
        margin-left: $padding-small;

        &-lg {
            margin-left: $padding-base;
        }
    }
}

.g-padding {
    &-top {
        padding-top: $padding-small;

        &-lg {
            padding-top: $padding-base;
        }
    }

    &-right {
        padding-right: $padding-small;

        &-lg {
            padding-right: $padding-base;
        }
    }

    &-bottom {
        padding-bottom: $padding-small;

        &-lg {
            padding-bottom: $padding-base;
        }
    }

    &-left {
        padding-left: $padding-small;

        &-lg {
            padding-left: $padding-base;
        }
    }
}

.g-relative {
    position: relative;
}

.g-no-scroll {
    overflow: hidden;
}

.g-white-alt-bg {
    background-color: $white-alt;
}

.g-spinner {
    animation: spinning linear 1s infinite;

    @keyframes spinning {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.g-pointer {
    cursor: pointer;
}