@import "../../variables.scss";

.b-activity-page {
  .e-container {
    max-width: $dashboardWidth + ($padding-base * 2);
    margin: 0 auto;
    padding-left: $padding-base !important;
    padding-right: $padding-base !important;
  }

  .e-search-bar {
    margin-top: $padding-small;
    background-color: rgba($black, 0.08);
    border-radius: 7px;
    display: flex;
    position: relative;
  }

  .e-search-icon {
    padding-left: $padding-small;
  }

  .e-search-clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $padding-small / 2;
    cursor: pointer;
  }

  .e-search-field {
    border: none;
    background-color: transparent;
    width: 100%;
    line-height: 36px;
    padding-right: $padding-small;
    color: $black;
    font-size: 14px;

    &:focus {
      outline: 0;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $grey;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $grey;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $grey;
      opacity: 1;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $grey;
      opacity: 1;
    }
  }

  .e-filters {
    margin-top: $padding-small;

    @media (min-width: $tablet-breakpoint) {
      max-width: $dashboardWidth + ($padding-base * 2);
      margin: $padding-small auto 0;
    }
  }

  .e-filter-wrapper {
    padding: 0 $padding-base;
    overflow-x: auto;
    white-space: nowrap;
  }

  .e-filter {
    display: inline-block;
    padding: 8px 10px;
    background-color: rgba($black, 0.08);
    color: rgba($black, 0.9);
    margin-right: 8px;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color ease 0.3s, color ease 0.3s;
    font-size: 14px;
    font-weight: 700;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }

    &.m-active,
    &:hover {
      background-color: rgba($black, 0.9);
      color: $white;
    }
  }

  .e-no-results {
    text-align: center;
    max-width: 215px;
    margin: 50px auto 0;

    @media (min-width: $tablet-breakpoint) {
      margin: 100px auto 0;
    }
  }

  .e-no-results-title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: $padding-base;
  }

  .e-no-results-description {
    font-size: 14px;
  }
}

.b-activity {
  display: flex;
  margin: ($padding-base + ($padding-small / 2)) 0;
  align-items: flex-start;

  &.m-clickable {
    cursor: pointer;
  }

  &.m-unread {
    font-weight: 700;
  }

  .e-activity-avatar {
    margin-right: $padding-small;
  }

  .e-activity-content {
    flex-grow: 1;
  }

  .e-activity-action {
    margin-left: $padding-base;
  }

  .e-action-btn {
    max-width: 112px;
    min-width: 112px;

    .e-label {
      font-size: 12px;
    }
  }

  .e-activity-group {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
    color: $grey;
  }

  .e-activity-msg {
    margin-bottom: 5px;
    line-height: 1.43;
    letter-spacing: -0.22px;
    font-size: 14px;
  }

  .e-activity-timestamp {
    font-size: 12px;
    color: $grey-alt-2;
    line-height: 1.67;
    letter-spacing: 0.41px;
  }
}