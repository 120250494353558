@import '../../variables.scss';
@import '../../mixins.scss';

.b-autocomplete {
  position: relative;

  .e-dropdown {
    position: absolute;
    left: -99999px;
    top: 54px;
    right: 99999px;
    transform: translateY(-20px);
    opacity: 0;
    transition: transform ease 0.3s, opacity ease 0.3s;
    background-color: $white;
    border-radius: 5px;
    z-index: $dropdownZIndex;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    @include mobile-scroll();
    max-height: 200px;

    &.m-open {
      left: 0;
      right: 0;
      transform: translateY(0);
      opacity: 1;
    }
  }

  .e-arrow {
    position: absolute;
    top: 28px;
    right: $padding-small;
    transform: translateY(-50%) rotate(0);
    transition: transform ease 0.3s;

    &.m-open {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .e-dropdown-item {
    position: relative;
    padding: $padding-small;
    transition: background-color ease 0.3s;
    cursor: pointer;

    &:hover,
    &.m-active {
      background-color: rgba($grey-alt, 0.7);
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: $padding-small;
      right: $padding-small;
      height: 1px;
      background-color: $grey-alt;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  &:focus {
    outline: 0;
  }
}