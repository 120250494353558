@import '../../variables.scss';

.b-transactions-svg {
    display: block;
    margin: $padding-base auto 0;
    
    rect[width="173"] {
        opacity: 0;
        transform: translateX(50px);
        animation: animLeft ease 0.5s forwards;

        @keyframes animLeft {
            from {
                opacity: 0;
                transform: translateX(50px);
            }
            to {
                opacity: 0.481;
                transform: translateX(0);
            }
        }
    }

    rect[width="119"],
    rect[width="86"] {
        opacity: 0;
        transform: translateX(-50px);
        animation: animRight ease 0.5s forwards;

        @keyframes animRight {
            from {
                opacity: 0;
                transform: translateX(-50px);
            }
            to {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    rect[width="119"] {
        animation-delay: 0.1s
    }

    rect[width="86"] {
        animation-delay: 0.2s
    }
}