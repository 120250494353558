@import '../../variables.scss';

.b-app-banner {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $black;
    height: $bannerHeightMobile;
    display: flex;
    flex-direction: column;
    padding: $padding-base $padding-small;
    z-index: 10;

    @media (min-width: $tablet-breakpoint) and (max-width: $tablet-breakpoint) {
        left: $menuWidth;
    }

    @media (min-width: $tablet-breakpoint + 1) {
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 ($padding-base * 2);
        height: $bannerHeight;
    }

    .e-description {
        color: $white;
        font-size: 14px;
        padding: 0 $padding-small $padding-small 0;

        @media (min-width: $tablet-breakpoint + 1) {
            font-size: inherit;
            padding: $padding-base $padding-base $padding-base 0;
        }
    }

    .e-app-btns {
        flex-grow: 1;
        display: flex;
        width: 100%;

        a {
            display: inherit;

            &:first-child {
                margin-right: $padding-small;
            }
        }

        @media (min-width: $tablet-breakpoint + 1) {
            width: auto;
            //margin-right: $padding-small;
            justify-content: flex-start;

            a:first-child {
                margin-right: $padding-small;
            }
        }
    }

    .e-close {
        position: absolute;
        top: ($padding-small / 2);
        right: ($padding-small / 2);
        cursor: pointer;
    }
}