@import "../../variables.scss";
@import "../../mixins.scss";

.b-group-tile {
  border-radius: 7px;
  background-color: $white;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(151, 151, 151, 0.1);
  padding: $padding-small;
  display: flex;
  align-items: flex-start;
  margin-bottom: $padding-base;
  flex: 0 0 auto;

  &.m-linked {
    cursor: pointer;
  }

  .e-group-details {
    max-width: calc(100% - (80px + #{$padding-small}));
    padding-left: $padding-small;
  }

  .e-group-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 7px;
    text-align: left;
    @include text-ellipse;
  }

  .e-group-balance {
    margin-top: 6px;
    color: $grey-alt-2;
  }

  .e-balance-type {
    font-size: 14px;
    font-weight: 700;

    &.m-owed {
      color: $owed-text;
    }

    &.m-owe {
      color: $owe-text;
    }
  }
}
