@import "../../variables.scss";

.b-btn {
  position: relative;
  border-radius: 30px;
  padding: $padding-small - 8px;
  min-width: 320px - ($padding-base * 2);
  border: 2px solid;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  transition: transform ease 0.2s, background-color ease 0.2s,
    border-color ease 0.2s, opacity ease 0.3s;
  color: $white;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: 0;
  }

  &.m-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .e-icon {
    position: absolute;
    top: $padding-small - 6px;
    left: $padding-small - 6px;
    bottom: $padding-small - 6px;
    background-color: $white;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.m-no-circle {
      border-radius: 0;
      background-color: transparent;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    img {
      max-height: 13px;
      max-width: 13px;
    }
  }

  .e-label {
    font-weight: 700;
    font-size: 22px;
    user-select: none;

    &.m-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;

      /* weird fix to align buttons */
      margin-top: 2px;
      margin-bottom: -2px;
    }
  }

  &.m-black {
    background-color: $black;
    border-color: $black;

    &.m-outline {
      color: $black;
      background-color: transparent;
      border-color: $black;

      .e-icon {
        background-color: $black;
      }
    }
  }

  &.m-primary {
    background-color: $pinkish-tan;
    border-color: $pinkish-tan;

    &.m-outline {
      color: $pinkish-tan;
      background-color: transparent;
      border-color: $pinkish-tan;

      .e-icon {
        background-color: $pinkish-tan;
      }
    }
  }

  &.m-secondary {
    background-color: $denim;
    border-color: $denim;

    &.m-outline {
      color: $denim;
      background-color: transparent;
      border-color: $denim;

      .e-icon {
        background-color: $denim;
      }
    }
  }

  &.m-future {
    background-color: $blue;
    border-color: $blue;

    &.m-outline {
      color: $blue;
      background-color: transparent;
      border-color: $blue;

      .e-icon {
        background-color: $blue;
      }
    }
  }

  &.m-google {
    background-color: $brand-google;
    border-color: $brand-google;
  }

  &.m-facebook {
    background-color: $brand-facebook;
    border-color: $brand-facebook;
  }

  &.m-small {
    .e-icon {
      top: 5px;
    }

    .e-label {
      font-size: 16px;

      &.m-loading {
        height: 18px;
      }
    }
  }

  &.m-inline {
    min-width: 0;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: $padding-small;
    padding-right: $padding-small;

    .e-icon {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      margin-right: 7px;
    }

    .e-label {
      font-size: 16px;
    }
  }
}
