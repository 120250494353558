@import "../../variables.scss";

.b-kins-page {
  max-width: $dashboardWidth + ($padding-small * 2);
  margin: 0 auto;
  padding: $padding-small $padding-small $padding-small;

  @media (min-width: $tablet-breakpoint) {
    margin: $padding-small auto 0;
  }

  .e-new-kin {
    padding: $padding-small;
    margin-bottom: $padding-base;
    border-radius: 16px;
    background-image: linear-gradient(129deg, #5f9ca9, #264e57 100%);

    @media (min-width: $tablet-breakpoint) {
      margin: (-$padding-small) (-$padding-small) $padding-base (-$padding-small);
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: $white;
      text-align: center;
      margin-top: $padding-base;

      &.m-link {
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .b-group-tile {
      margin-bottom: 0;
    }
  }

  .e-new-success {
    margin: $padding-base auto;
    max-width: 154px;
    display: block;
  }

  .e-expense-button {
    font-size: 16px;
    margin: $padding-base auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: ($padding-base / 2) $padding-base;
    border-radius: 30px;
    z-index: 1;
    white-space: nowrap;
  }

  .e-kins-title {
    font-size: 32px;
    margin: $padding-base 0;

    @media (min-width: $tablet-breakpoint) {
      margin-top: 0;
    }
  }
}
