@import "variables.scss";

.b-grid {
  display: flex;

  &.m-full-height {
    height: 100%;

    &.m-include-header {
      &-mobile {
        height: 100vh;
      }

      @media (min-width: $tablet-breakpoint) {
        height: calc(100vh - #{$headerHeight});

        &.m-include-banner {
          height: calc(100vh - (#{$headerHeight} + #{$bannerHeight}));
        }
      }
    }
  }

  &.m-full-width {
    width: 100%;
  }

  &.m-vertical {
    flex-direction: column;
  }

  &.m-space-between {
    justify-content: space-between;
  }

  &.m-justify-center {
    justify-content: center;
  }

  &.m-align-center {
    align-items: center;
  }

  .e-col {
    &.m-auto {
      flex-grow: 1;
    }

    &.m-scroll-auto {
      overflow: auto;
    }

    &.m-scroll {
      overflow: auto;
      height: 100vh;
    }

    &.m-shrink {
      flex-shrink: 0;
    }

    &.m-banner-height {
      height: calc(100vh - #{$bannerHeightMobile});

      @media (min-width: $tablet-breakpoint + 1) {
        height: calc(100vh - #{$bannerHeight});
      }
    }

    &.m-banner-padding {
      padding-top: $bannerHeightMobile;

      @media (min-width: $tablet-breakpoint + 1) {
        padding-top: 0;
      }
    }
  }
}
