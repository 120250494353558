@import '../../variables.scss';
@import '../../mixins.scss';

.b-profile-settings-name {
  .e-settings-name {
    font-size: 20px;
    font-weight: 700;
    @include text-ellipse();
  }

  .e-name-save {
    margin-top: 12px;
    align-self: flex-end;
  }
}