@import "../../variables.scss";

.b-expense-split {
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: $white-alt;
  border-radius: 5px;
  padding: $padding-small;
  margin-top: 8px;
  margin-bottom: 10px;

  .e-split-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: $padding-base;
  }

  .e-split-list {
    overflow: auto;
  }

  .e-split-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid $grey-alt;
  }

  .e-split-all-label {
    font-weight: 700;
  }

  .e-split-msg {
    margin-top: $padding-base;
    line-height: 37px;
    font-size: 14px;
    color: $scarlet;
  }

  .e-split-msg-amount {
    font-size: 18px;
    font-weight: 700;
  }
}
