@import "../variables.scss";
@import "../mixins.scss";

.b-page-modal {
  @media (max-width: $tablet-breakpoint - 1) {
    opacity: 0;
    transform: translateY(50px);
  }

  // Makes the page modal styles void without adding too much component logic for mobile devices
  &.m-open {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transform: translateY(0);
    transition: transform ease 0.3s;
    background-color: $white;
    z-index: $pageModalZIndex;

    .e-head {
      position: relative;
      height: $pageModalHeadMobile;
      background-color: $white-alt;
      box-shadow: 0 1px 2px 0 rgba(39, 39, 39, 0.4);
      z-index: 1;

      &.m-condensed-header {
        height: $pageModalHeadMobile - 40px - 41px; // Tab height (40px)
      }
    }

    .e-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 64px $padding-base 0;
    }

    .m-condensed-header {
      .e-title {
        padding: 0;
        justify-content: flex-start;
      }
    }

    .e-menu-icon {
      position: absolute;
      top: 0;
      left: 0;
      padding: $padding-base;
    }

    .m-condensed-header {
      .e-menu-icon {
        position: relative;
      }
    }

    .e-avatar-list {
      margin-right: 0;
    }

    .e-settings-icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: $padding-base;
    }

    .e-body {
      height: calc(100vh - #{$pageModalHeadMobile});

      &.m-condensed-header {
        height: 100%;
      }
    }

    .e-kin {
      justify-content: center;
      max-width: calc(100% - 125px);
      min-width: calc(100% - 4.5rem);
    }

    .e-kin-title {
      margin-left: 10px;
      max-width: calc(100vw - 56px - 25px - 10px - 64px);
      @include text-ellipse;

      align-self: center;
      flex: 1 1 auto;
      text-align: left;
    }
  }

  .e-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
  }

  .e-body {
    height: calc(100% - (#{$pageModalHeadMobile} + #{$headerHeight} + 54px));
    padding-bottom: 75px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  @media (min-width: $tablet-breakpoint) {
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(0);
    background-color: $white;
    z-index: $pageModalZIndex;
    height: 100vh;

    .e-head {
      height: $pageModalHead;
      background-color: $white-alt;
      z-index: 1;

      &.m-condensed-header {
        height: $pageModalHead - 64px; // Tab height
      }
    }

    .e-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: ($padding-base / 2) $padding-base;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      min-height: 4.5rem;

      &.e-container {
        padding: ($padding-base / 2) $padding-base;
      }
    }

    .e-kin {
      max-width: calc(100% - 150px);
      min-width: auto;
    }

    .m-person {
      .e-kin {
        max-width: 100%;
      }
    }

    .e-head-title {
      max-width: 100%;
      margin-right: $padding-small;
      @include text-ellipse;
    }

    .e-head-title-balance {
      font-size: 12px;
      font-weight: 300;
      color: $grey-alt-2;
      margin-top: 5px;

      span {
        font-weight: 700;

        &.m-owed {
          color: $owed-text;
        }

        &.m-owe {
          color: $owe-text;
        }
      }
    }

    .e-head-btn {
      min-width: 112px;
      padding-left: $padding-base;
      padding-right: $padding-base;

      .e-label {
        font-size: 16px;
      }
    }

    .e-body {
      height: calc(100vh - #{$pageModalHead});

      &.m-condensed-header {
        height: calc(100vh - #{$pageModalHead} + 64px); // Tab height
      }

      @media (min-width: $tablet-breakpoint + 1) {
        height: calc(100vh - #{$pageModalHead});

        &.m-condensed-header {
          height: calc(100vh - #{$pageModalHead} + 64px); // Tab height
        }
      }
    }
  }

  @media (min-width: $tablet-breakpoint + 1) {
    height: 100vh;
  }

  .e-avatar-list {
    margin-right: $padding-small;
  }

  .e-settings-icon {
    cursor: pointer;

    &.m-inactive {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.g-app-banner {
  .b-page-modal {
    @media (min-width: $tablet-breakpoint) {
      height: calc(100vh - #{$bannerHeightMobile});

      .e-body {
        height: calc(100vh - (#{$pageModalHead} + #{$bannerHeightMobile}));

        &.m-condensed-header {
          height: 100%;
        }
      }
    }

    @media (min-width: $tablet-breakpoint + 1) {
      height: calc(100vh - #{$bannerHeight});

      .e-body {
        height: calc(100vh - (#{$pageModalHead} + #{$bannerHeight}));

        &.m-condensed-header {
          height: calc(
            100vh - (#{$pageModalHead} + #{$bannerHeight}) + 64px
          ); // Tab height
        }
      }
    }
  }
}

.e-kin-flex {
  min-width: 100%;
  max-height: 4.5rem;
  display: flex;
}
