@import "../../variables.scss";

.b-person-page {
  .e-container {
    max-width: $dashboardWidth + ($padding-base * 2);
    margin: 0 auto;
    padding: $padding-base;
  }

  .e-balance-action {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $padding-base;
    background-color: $white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .e-action-label {
    font-size: 14px;
    font-weight: 700;
    color: $grey;
  }

  .e-action-amount {
    font-size: 24px;
    font-weight: 700;

    &.m-owed {
      color: $owed-text;
    }

    &.m-owe {
      color: $owe-text;
    }
  }

  .e-action-btn {
    margin-top: 20px;
    width: 100%;
    padding: $padding-base - 4px;
    border-radius: 40px;
  }

  .e-invite-card {
    @media all and (min-width: $tablet-breakpoint) {
      display: flex;
      align-items: center;
      background: #fafafa;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      padding: $padding-base $padding-small;
    }

    &-message {
      flex: 2 2 60%;

      @media all and (min-width: $tablet-breakpoint) {
        margin-bottom: 0;
      }
    }
    &-cta {
      padding-left: $padding-base;
      flex: 1 1 40%;

      &-action {
        min-width: 100%;
      }
    }
  }
}
