@import "../../variables.scss";

.e-campaign-kin {
  padding: $padding-small;
  margin-bottom: $padding-base;
  border-radius: 16px;
  background-image: linear-gradient(129deg, #77784c, #6a6b3e 100%);

  @media (min-width: $tablet-breakpoint) {
    margin: 0 0 $padding-base 0;
  }

  p {
    font-size: 16px;
    font-weight: 600;
    color: $white;
    text-align: center;
    margin-top: $padding-base;

    &.m-link {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .b-group-tile {
    margin-bottom: 0;
  }
}

.e-campaign-success {
  margin: $padding-base auto;
  max-width: 154px;
  display: block;
}

.e-campaign-emoji {
  max-width: 28ch;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &-narrow {
    max-width: 25ch;
  }
}
