@import "../../variables.scss";

.b-signin-page {
  .e-hero {
    width: 80%;
    max-width: 320px;
    margin: 0 auto 2rem;
    display: block;
  }

  .e-title {
    font-size: 24px;
    margin-bottom: 41px;

    @media (min-width: $tablet-breakpoint) {
      font-size: 32px;
      margin-bottom: 60px;
    }
  }

  .e-purpose {
    font-size: 24px;

    @media (min-width: $tablet-breakpoint) {
      font-size: 28px;
    }
  }
}
