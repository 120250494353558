@import "../../variables.scss";

.b-balance-summary-person {
  display: grid;
  border-radius: 7px;
  grid-template-columns: 1fr 120px;
  grid-template-rows: auto auto;
  grid-template-areas:
    "message avatar"
    "buttons buttons";
  grid-gap: 1rem;
  background: $grey-alt;
  padding: 1rem;
}
.m-balance-summary-person {
  &\:danger {
    background: rgba($owe-text, 0.1);

    .e-balance-summary-person-amount {
      color: $owe-text;
    }
  }

  &\:success {
    background: rgba($denim, 0.1);

    .e-balance-summary-person-amount {
      color: $denim;
    }
  }
}

.e-balance-summary-person-title {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
}

.e-balance-summary-person-amount {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.85;
  margin: 0;
}

.e-balance-summary-person-avatar-container {
  flex: 0 0 120px;
  width: 120px;
  box-sizing: content-box;
  padding-top: 1rem;
  padding-right: 2rem;

  @media all and (min-width: $tablet-breakpoint) {
    padding-right: 1rem;
  }
}

@media all and (min-width: $tablet-breakpoint) {
  .e-balance-summary-person-avatar {
    position: absolute;
    top: 1rem;
  }
}

.l-balance-summary-message {
  grid-area: message;
}

.l-balance-summary-amount {
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
}
