@import '../../variables.scss';

.b-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.6;
    background-color: black;
    z-index: $overlayForSettingsZIndex;

    @media (min-width: $tablet-landscape-breakpoint + 1) {
        display: none;
    }
}