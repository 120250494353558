@import "../../variables.scss";

.b-expense-modal {
  width: 100%;
  margin: 0 auto;

  height: 100%;
  display: flex;
  flex-direction: column;

  .e-expense-toggle {
    margin-top: $padding-small;
  }

  .e-expense-field {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: ($padding-small / 2) auto;
    border: 1px solid $white-alt;
    border-radius: 5px;
    padding: $padding-small;
    background-color: $white-alt;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);

    &.m-editing {
      border-color: $pinkish-tan;

      .e-input {
        caret-color: $pinkish-tan;
      }

      &.m-future {
        border-color: $blue;

        .e-input {
          caret-color: $blue;
        }
      }
    }
  }

  .e-expense-currency {
    font-size: 22px;
    color: $pinkish-tan;
    font-weight: 700;
    padding-right: $padding-small;
    transition: color ease 0.2s;

    &.m-future {
      color: $blue;
    }
  }

  .e-expense-input {
    .e-input {
      padding: 0;
      text-align: center;
      background-color: transparent;
      font-size: 40px;
      font-weight: 700;
    }

    &.m-description {
      width: 100%;

      .e-input {
        font-size: 18px;
        line-height: 47px;
        font-weight: 400;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $grey-alt-2;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $grey-alt-2;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: $grey-alt-2;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: $grey-alt-2;
        }
      }
    }
  }

  .e-expense-split-user {
    margin-top: $padding-small;
  }

  .e-expense-details {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);
    background-color: $white-alt;
    border-radius: 5px;
    padding: 8px $padding-small;
    margin-bottom: 8px;
    cursor: pointer;
    transition: opacity ease 0.3s;

    &.m-disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.m-error {
      border-color: $scarlet;
      border-width: 1px;
      border-style: solid;
    }
  }

  .e-detail-label {
    flex-grow: 1;
    font-size: 14px;
    text-align: left;
  }

  .e-expense-calendar {
    position: absolute;
    left: -9999px;
    right: 0;
    bottom: calc(100% + 8px);
    transition: transform ease 0.3s, opacity ease 0.3s;
    transform: translateY(20px);
    opacity: 0;
    transition: opacity ease 0.3s;

    &.m-disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.m-open {
      left: 0;
      transform: translateY(0);
      opacity: 1;
    }
  }

  .e-expense-btn {
    width: 100%;
    margin-top: 30px;
    flex: 0 0 auto;

    &.m-sticky {
      position: sticky;
      bottom: 0;
    }

    .e-label {
      font-size: 14px;
    }
  }

  .e-expense-msg {
    margin-top: $padding-base;
    line-height: 37px;
    font-size: 14px;
    color: $scarlet;
  }

  .e-expense-msg-amount {
    font-size: 18px;
    font-weight: 700;
  }
}
