@font-face {
    font-family: "Cerebri Sans";
    src: url("cerebri-sans/CerebriSans-Regular.woff2") format("woff2"),
        url("cerebri-sans/CerebriSans-Regular.woff") format("woff"),
        url("cerebri-sans/CerebriSans-Regular.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: "Cerebri Sans";
    src: url("cerebri-sans/CerebriSans-Medium.woff2") format("woff2"),
        url("cerebri-sans/CerebriSans-Medium.woff") format("woff"),
        url("cerebri-sans/CerebriSans-Medium.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
    font-weight: 600;
}

@font-face {
    font-family: "Cerebri Sans";
    src: url("cerebri-sans/CerebriSans-Bold.woff2") format("woff2"),
        url("cerebri-sans/CerebriSans-Bold.woff") format("woff"),
        url("cerebri-sans/CerebriSans-Bold.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: "Cerebri Sans";
    src: url("cerebri-sans/CerebriSans-ExtraBold.woff2") format("woff2"),
        url("cerebri-sans/CerebriSans-ExtraBold.woff") format("woff"),
        url("cerebri-sans/CerebriSans-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
    font-weight: 800;
}