@import '../../variables.scss';

.b-avatar-upload {
  position: relative;
  border: 2px solid $white;
  overflow: hidden;

  &.m-editing {
    cursor: pointer;

    .e-upload {
      transform: translateY(0);
    }
  }

  &:before {
    position: absolute;
    content: '';
    background-color: rgba($white, 0.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ease 0.3s;
    z-index: 1;
  }

  &.m-uploading {

    &:before {
      z-index: 3;
    }

    &:before,
    .e-upload-loader {
      opacity: 1;
    }
  }

  .e-upload-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity ease 0.3s;
    z-index: 4;
  }

  .e-upload-input {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }

  .e-upload {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(100%);
    transition: transform ease 0.3s;
    padding: 13px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    color: $white;
    z-index: 1;
  }
}