@import '../../variables.scss';

.b-payment-modal {
  .e-payment-users {
    display: flex;
    justify-content: center;
    margin: $padding-base 0;
  }

  .e-payment-field {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 320px;
    width: 100%;
    margin: $padding-base auto;
    border: 1px solid $white-alt;
    border-radius: 5px;
    padding: $padding-small 0 $padding-small $padding-small;
    background-color: $white-alt;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);

    .e-input {
      padding-right: (40px + $padding-small) !important;
    }

    &.m-editing {
      border-color: $pinkish-tan;
      padding-right: $padding-small;

      .e-input {
        caret-color: $pinkish-tan;
        padding-right: 0 !important;
      }
    }

    &.m-paid {
      border-color: $denim;

      .e-input {
        caret-color: $denim;
      }
    }
  }

  .e-payment-currency {
    font-size: 22px;
    color: $pinkish-tan;
    font-weight: 700;
    padding-right: $padding-small;

    &.m-paid {
      color: $denim;
    }
  }

  .e-payment-input {
    .e-input {
      padding: 0;
      text-align: center;
      background-color: transparent;
      font-size: 40px;
      font-weight: 700;
    }
  }

  .e-payment-btn {
    .e-label {
      font-size: 14px;
    }
  }

  .e-payment-edit {
    position: absolute;
    right: $padding-small;
    padding-left: $padding-small;
    pointer-events: none;
    cursor: pointer;

    &.m-confirm {
      position: relative;
      right: auto;
      pointer-events: all;
    }
  }

  .e-payment-fyi {
    max-width: 320px;
    margin: 0 auto;
  }
}