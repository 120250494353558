@import '../../variables.scss';
@import '../../mixins.scss';

.b-expense-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid $grey-alt;
  transition: opacity ease 0.3s;

  &.m-disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:last-child {
    border-bottom: none;
  }

  .e-user-check {
    margin-right: 10px;
  }

  .e-user-name {
    flex-grow: 1;
    text-align: left;
    max-width: calc((100% - 56px) / 2);
    @include text-ellipse;
  }

  .e-user-amount {
    max-width: calc((100% - 56px) / 2);
    margin-right: 8px;
  }

  .e-user-input {

    .e-input {
      font-size: 22px;
      background-color: transparent;
      padding: 0;
      text-align: right;
    }

    &.m-edited {
      .e-input {
        font-weight: 700;
      }
    }
  }

  .e-user-edit {
    cursor: pointer;
  }
}