@import "../../variables.scss";

.b-modal {
  position: fixed;
  top: 0;
  left: -150%;
  bottom: 0;
  right: 150%;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: $modalZIndex;
  display: flex;

  @media (min-width: $tablet-breakpoint) {
    padding: 24px;
  }

  .e-modal-sticky-button {
    flex: 1 1 auto;
  }

  &.m-center {
    text-align: center;

    .e-modal-box {
      padding-left: 183px;
      padding-right: 183px;

      display: flex;
      flex-direction: column;

      h1 {
        padding-left: 18px;
        padding-right: 18px;
      }

      @media (max-width: 830px) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &.m-open {
    left: 0;
    right: 0;

    .e-modal-box {
      opacity: 1;
      transform: translateY(0);
    }

    .e-overlay {
      opacity: 1;
      left: 0;
      right: 0;
    }
  }

  .e-modal-box {
    position: relative;
    padding: 70px 84px;
    background-color: $white;
    max-width: 830px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1002;
    overflow-y: auto;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity ease 0.3s, transform ease 0.3s;

    @media (min-width: $tablet-breakpoint) {
      border-radius: 10px;
      margin: auto;
    }

    @media (max-width: 830px) {
      text-align: center;
      padding: 80px 24px 44px;
    }
  }

  &.m-modal-short .e-modal-box {
    @media (max-width: 830px) {
      padding: 26px 24px 10px;
    }
  }

  .e-img-icon {
    position: relative;
    background-color: $pinkish-tan;
    border-radius: 50%;
    min-width: 222px;
    min-height: 222px;
    margin: 0 auto 20px;
    overflow: hidden;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.m-reminder {
      background-color: $denim;
    }

    &.m-small {
      width: 106px;
      height: 106px;
      margin-bottom: 54px;
    }
  }

  .e-close,
  .e-back {
    position: absolute;
    top: $padding-base;
    left: $padding-base;
    z-index: 1;
    cursor: pointer;
  }

  .e-back {
    display: none;
  }

  &.m-modal-back {
    .e-back {
      display: block;
    }
    .e-close {
      display: none;
    }
  }

  .e-back {
    display: none;
  }

  &.m-modal-back {
    .e-back {
      display: block;
    }
    .e-close {
      display: none;
    }
  }

  .e-modal-title {
    position: absolute;
    top: $padding-base;
    left: $padding-base;
    right: $padding-base;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;

    @media (min-width: $tablet-breakpoint) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  h1 {
    font-size: 32px;
    font-weight: 800;
    margin-top: 0;

    @media (min-width: $tablet-breakpoint) {
      line-height: 52px;
      font-size: 44px;
    }
  }

  h2 {
    font-size: 34px;
    line-height: 40px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 48px;
  }

  .e-overlay {
    position: fixed;
    top: 0;
    left: -150%;
    bottom: 0;
    right: 150%;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background-color: rgba($black, 0.8);
    opacity: 0;
    transition: opacity ease 0.3s;
    cursor: pointer;
  }

  p {
    font-size: 14px;
    line-height: 1.79;
  }

  .e-kin-modal-logo {
    position: absolute;
    top: 1.65rem;
    width: 50px;
    box-sizing: border-box;
    left: calc(50% - 25px);

    @media all and (min-width: $tablet-breakpoint) {
      display: none;
    }
  }
}

.b-new-kin-modal {
  h2 {
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 32px;
    font-weight: 700;

    @media (min-width: $tablet-breakpoint) {
      margin-bottom: 8px;
    }
  }

  p {
    font-size: 16px;
    color: $grey;
    line-height: normal;

    strong {
      color: #000;
    }

    &.m-note {
      font-size: 12px;
    }
  }

  .e-group-balance {
    text-align: left;
    font-size: 12px;
    margin-top: 10px !important;
    color: $grey !important;
  }

  .e-kin-info {
    display: flex;
    align-items: center;

    .b-input {
      margin-left: $padding-small;
      flex-grow: 1;
    }
  }

  .e-kin-name,
  .e-kin-email {
    .e-input {
      font-size: 18px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .e-upload-input {
    min-width: 90px;
  }

  .e-kin-purpose,
  .e-kin-share,
  .e-kin-currency {
    margin: 32px 0 $padding-small;
  }

  .e-field-label {
    font-size: 10px;
    font-weight: bold;
    color: $grey;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 8px;
  }

  .e-purpose-field {
    .e-input {
      font-size: 16px;
      font-weight: bold;
      color: $grey;
    }
  }

  .e-kin-share {
    position: relative;
  }

  .e-kin-share-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    padding: 15px;
    transform: translateY(-50%);
  }

  .e-share-field {
    .e-input {
      padding-right: 60px;
    }
  }

  .e-kin-share-action {
    margin: 32px 0 ($padding-base * 4);
  }

  .e-action {
    width: 100%;
    flex: 0 0 auto;
  }
}
