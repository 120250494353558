@import '../../variables.scss';

.b-404-error {

    h1 {
        margin-top: $padding-base * 4;

        @media (max-width: $tablet-breakpoint - 1) {
            text-align: center;
            margin-bottom: $padding-small;
        }

        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
            font-size: 32px;
            margin-bottom: $padding-base;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }

    .e-content {
        padding: 0 $padding-small;
    }

    .e-purpose {
        margin: $padding-base 0 34px;
        font-size: 24px;

        @media (max-width: $tablet-breakpoint - 1) {
            text-align: center;
        }

        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
            font-size: 28px;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }

    .e-center {
        text-align: center;

        &.m-block {
            margin: 0 auto;
            display: block;
        }

        @media (min-width: $tablet-breakpoint) {
            text-align: left;

            &.m-block {
                margin: 0;
                text-align: center;
            }
        }
    }
}