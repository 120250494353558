@import '../../variables.scss';

.b-notice-snackbar {
    background-color: $pale-teal;
    border-radius: 5px;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
    padding: $padding-small;
    color: $white;
    position: fixed;
    left: $padding-small;
    right: $padding-small;
    bottom: $padding-small;
    z-index: $errorZIndex;
    width: calc(100% - (#{$padding-small} * 2));
    max-width: 500px;
    transform: translateX(calc(-100% - (#{$padding-small} * 2)));
    opacity: 0;
    transition: transform ease 0.3s, opacity ease 0.3s;
    text-align: left;

    @media (min-width: $tablet-breakpoint) {
        left: $padding-base;
        bottom: $padding-base;
        right: auto;
        transform: translateX(calc(-100% - (#{$padding-base} * 2)));
    }

    &.m-active {
        transform: translateX(0);
        opacity: 1;

        @media (min-width: $tablet-breakpoint) {
            transform: translateX(0);
        }
    }
}