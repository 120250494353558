@import '../../variables.scss';

header {
    display: flex;
    justify-content: space-between;
    height: $headerHeightMobile;
    padding: $padding-base;
    box-sizing: border-box;

    .e-sign-out {
        line-height: 30px;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 1.06px;
        font-weight: 700;
        opacity: 0.4;
    }

    @media (min-width: $desktop-breakpoint) {
        padding: $headerPadding $padding-base * 2;
    }

    @media (min-width: $tablet-breakpoint) {
        height: $headerHeight;

        &.m-margin-bottom {
            margin-bottom: $headerMarginBottom;
        }
    }

    &.m-sidebar {
        justify-content: flex-end;

        @media (min-width: $desktop-breakpoint) {
            padding-top: $padding-base;
            padding-left: $padding-base;
            padding-right: $padding-base;
        }
    }
}