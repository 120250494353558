@import "../../variables.scss";
@import "../../mixins.scss";

.b-kin-settings {
  position: relative;
  background-color: $very-light-link;
  width: $kinSettingsWidth;
  @include box-shadow();

  @media (max-width: $mobile-small-breakpoint) {
    width: $kinSettingsWidthXS;
  }

  @media (max-width: $tablet-landscape-breakpoint) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $kinSettingsZIndex;
    animation: slideLeft ease 0.3s forwards;

    @keyframes slideLeft {
      from {
        transform: translateX(100%);
      }

      to {
        transform: translateX(0);
      }
    }
  }

  .e-close {
    position: absolute;
    top: $padding-base;
    left: $padding-base;
    z-index: 1;
    cursor: pointer;
  }

  .e-edit {
    position: absolute;
    top: $padding-base;
    right: $padding-base;
    z-index: 1;
    cursor: pointer;
  }

  .e-panel {
    max-height: calc(100vh - 64px);
    padding: 0 $padding-base;
  }

  .e-kin-avatar {
    margin: 72px auto 0;
  }

  .e-kin-title {
    margin-top: $padding-small;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .e-kin-purpose {
    margin-top: $padding-small;
    font-size: 16px;
    text-align: center;
  }

  .e-divider-title {
    margin-top: 40px;
    margin-bottom: $padding-small;
    font-size: 12px;
    text-transform: uppercase;
  }

  .e-people {
    margin-left: -$padding-base;
    margin-right: -$padding-base;
    padding-left: $padding-base;
    padding-right: $padding-base;
    background-color: #fafafa;
    margin-bottom: $padding-base;
  }

  .e-person {
    position: relative;
    padding: 12px 0;
  }

  .e-person-name {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.02rem;
    font-kerning: normal;
    width: 80px;

    .m-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .e-kin-save {
    margin-top: $padding-base;
    align-self: flex-end;
  }

  .e-leave-kin {
    padding-left: $padding-base;
    font-size: 12px;
  }

  .e-leave-btn {
    padding: $padding-base;
    cursor: pointer;
  }

  .e-kin-share {
    margin: 32px 0 $padding-small;
    position: relative;
  }

  .e-kin-share-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    padding: 15px;
    transform: translateY(-50%);
  }

  .e-share-field {
    .e-input {
      background-color: #fff;
      padding-right: 60px;
    }
  }

  .e-kin-share-action {
    margin: 32px 0 ($padding-base * 4);
  }

  .e-kin-reinvite {
    display: flex;
    button {
      cursor: pointer;
      line-height: 1;
      height: 24px;
      color: black;
      background-color: transparent;

      transition: opacity 0.5s ease-in-out;

      &:focus {
        outline: transparent;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
        transition: opacity 0.5s ease-in-out;
      }
    }
    .m-text {
      border-radius: 24px;
      font-size: 10px;
      font-weight: 600;
      padding: 0 16px;
      border: 1px solid black;
      min-width: 112px;
    }
    .m-icon {
      border: 0px solid transparent;
      padding: 0;
      margin-left: 6px;
    }
  }
}
