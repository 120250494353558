
.b-pill-wrapper {
    display: flex;
    justify-content: flex-end;
}

.b-pill {
    border-radius: 5px;
    padding: 15px 20px;
    display: inline-flex;
    align-items: center;
    min-width: 140px;
    max-width: 250px;
    margin-left: auto;
    margin-bottom: 15px;
    animation: slideUp 0.3s forwards;
    opacity: 0;
    transform: translateY(30px);
    
    @keyframes slideUp {
        0%   {
            opacity: 0;
            transform: translateY(30px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    &.m-pink {
        background-color: rgba(243, 136, 135, 0.160429);
    }
    
    &.m-blue {
        background-color: rgba(55, 111, 112, 0.0987216);
    }
    
    &.m-grey {
        background-color: rgba(151, 151, 151, 0.16);
    }
    
    .e-avatars {
        margin-left:-40px;
    }
    
    .e-msg {
        font-size: 16px;
        font-weight: 700;
        padding-left: 15px;
    }
}
