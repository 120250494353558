@import '../../variables.scss';

.b-debugger {
  position: fixed;
  bottom: $padding-small;
  left: $padding-small;
  z-index: $errorZIndex;
  outline: 0;

  .e-test-icon {
    background-color: $pinkish-tan;
    padding: 5px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 10px rgba($black, 0.2);

    img {
      max-width: 15px;
    }
  }

  .e-debugger-panel {
    position: fixed;
    top: -999px;
    left: ($padding-small * 2) + 35px;
    transform: translateX(-30px);
    opacity: 0;
    background-color: $white;
    box-shadow: 0 0 10px rgba($black, 0.2);
    border-radius: 5px;
    transition: transform ease 0.3s, opacity ease 0.3s;

    &.m-open {
      top: auto;
      bottom: $padding-small;
      transform: translateX(0);
      opacity: 1;
    }
  }

  .e-variation {
    padding: $padding-small;
    cursor: pointer;

    &:hover {
      background-color: rgba($pinkish-tan, 0.1)
    }

    &.m-label {
      text-transform: uppercase;
      font-size: 11px;
      color: $grey-alt-2;

      &:first-child {
        border-bottom: 1px solid rgba($black, 0.05);
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }

      &:last-child {
        border-top: 1px solid rgba($black, 0.05);
      }
    }

    &.m-close {
      color: $pinkish-tan;
    }
  }
}