@import '../../variables.scss';

.b-new-group-tile {
    position: relative;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(151, 151, 151, 0.1);
    padding: $padding-base $padding-base $padding-small $padding-base;
    display:flex;
    align-items: flex-start;
    margin-top: $padding-base;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    font-weight: 700;
    color: $white;

    height: 112px;

    @media (min-width: $tablet-breakpoint) {
        height: 144px;
    }

    &:after {
        position: absolute;
        content: '';
        top:0;
        right: 0;
        bottom: 0;
        left:0;
        background-color: $black;
        opacity: 0.4;
    }

    .e-title,
    .e-cta {
        position: relative;
        display: block;
        width: 100%;
        z-index: 1;
    }

    .e-title {
        font-size: 24px;
    }

    .e-cta {
        font-size: 18px;
        text-align: right;
    }
}