@import '../../variables.scss';

.b-settle-svg {
    display: block;
    margin: $padding-base auto 0;

    path[fill="#173D6A"],
    path[fill="url(#d)"],
    path[fill="url(#a)"],
    path[fill="url(#c)"],
    path[fill="#EBE0DA"],
    path[fill="url(#b)"],
    path[fill="url(#e)"],
    path[fill="#376F70"],
    path[fill="#F38887"] {
        opacity: 0;
        transform: scale(0.5);
        animation: animIn ease 0.5s forwards;
        transform-origin: center center;
    }

    path[fill="url(#c)"],
    path[fill="#EBE0DA"],
    path[fill="url(#b)"] {
        animation-delay: 0.1s;
    }

    path[fill="url(#e)"],
    path[fill="#376F70"],
    path[fill="#F38887"] {
        animation-delay: 0.2s;
    }

    @keyframes animIn {
        from {
            opacity: 0;
            transform: scale(0.5);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
}