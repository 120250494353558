@import '../../variables.scss';

.b-footer {
    position: relative;
    background-color: $black;
    padding: 72px $padding-base;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 52px;

    @media (max-width: $mobile-small-breakpoint) {
        padding: $padding-base;
    }

    @media (min-width: $desktop-breakpoint) {
        margin-top: 123px;
    }

    @media (min-width: $tablet-breakpoint) {
        margin-top: 123px;
    }

    .e-link {
        color: $white;
        text-decoration: none;
        margin: 0 $padding-small;

        @media (max-width: $mobile-small-breakpoint) {
            display: block;
            margin: $padding-small 0;
        }
    }
}