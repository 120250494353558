@import '../../variables.scss';

.b-avatar {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: $white;
    border-style: solid;
    border-color: $white;

    &.m-initial {
        background-color: $pale-teal;
    }

    &.m-more {
        background-color: $brown-grey;
        border-radius: 11px;
        padding: 0 5px;
    }

    &.m-transparent {
        background-color: transparent;
        border-color: transparent;
    }

    .e-img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    .e-initial {
        color: $white;
        line-height: 24px;
        font-weight: 800;
        text-transform: uppercase;
    }
}