@import '../../variables.scss';

.b-input {
    [disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .e-label {
        display: block;
        margin-bottom: $padding-small;
    }

    textarea.e-input {
        height: 120px;
        resize: none;
    }

    .e-input {
        border: none;
        border-radius: 5px;
        background-color: $grey-field;
        font-size: 16px;
        width: 100%;
        padding: $padding-base $padding-small;
        box-sizing: border-box;

        &:focus {
            outline: 0;
        }

        &.m-error {
            border: 2px solid $pinkish-tan !important;
        }
    }

    .e-error {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-weight: 600;
        font-size: 12px;
        color: $grey;

        img {
            margin-right: 8px;
        }
    }

    &.m-small {
        .e-input {
            padding: $padding-small;
            font-size: 16px;
        }
    }

    &.m-no-border-style {
        .e-input {
            background-color: $white;
        }
    }
}