@import "../../variables.scss";

[class^="l-summary-cards"] {
  display: flex;
  margin-bottom: 1.5rem;
}

.l-summary-cards {
  &\:horizontal {
    flex-wrap: nowrap;

    .b-summary-card {
      margin: 0 1rem 0 0;
      &:last-child {
        margin-right: 0;
      }
    }

    &.in-container {
      align-items: stretch;
    }
  }
  &\:vertical {
    flex-wrap: wrap;

    .b-summary-card {
      margin: 0;
      margin-bottom: 1rem;
      min-width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

[class^="b-summary-card"] {
  position: relative;
  border-radius: 7px;
  text-align: right;
  margin: 0;
  padding: 0.5rem 1.25rem;
  flex: 1 1 auto;
  width: 100%;

  p {
    margin: 0;
    padding: 0;
    line-height: 1.143;
  }

  .e-summary-card-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $black;
    font-size: 0.5rem;
    line-height: 0.6rem;
    text-transform: uppercase;

    span {
      margin-left: auto;
    }
  }

  [class^="e-summary-card-amount"] {
    font-weight: bolder;
    letter-spacing: -0.075rem;
  }

  .e-summary-card-amount {
    &\:secondary {
      font-size: 0.75rem;
      font-weight: normal;
    }
  }

  .e-summary-card-icon {
    position: relative;
    left: -0.5rem;
    width: 1.5rem;
  }
}

.m-summary-card-type {
  &\:danger {
    background: rgba($owe-text, 0.1);
    color: $owe-text;
  }
  &\:success {
    background: rgba($denim, 0.1);
    color: $denim;
  }
}

.m-summary-card-spacing {
  &\:tight {
    padding-top: 0.75rem;

    .e-summary-card-amount\:primary {
      margin-bottom: 0rem;
    }
  }
  &\:loose {
    padding-bottom: 0.75rem;

    .e-summary-card-amount\:primary {
      margin-bottom: 0.35rem;
    }
  }
}
