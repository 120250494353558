@import "../../variables.scss";
@import "../../mixins.scss";

.b-type-divider {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: $padding-base 0;

  &:not(:first-child) {
    padding-top: 52px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: -50vw;
      right: -50vw;
      bottom: 0;
      border-top: 1px solid $grey-alt;
      z-index: -1;
    }
  }

  .e-type {
    font-size: 18px;
    font-weight: 700;
  }

  .e-estimate {
    font-size: 14px;
    font-weight: 300;
    color: $grey;

    strong {
      font-size: 16px;
      font-weight: 700;
      color: initial;
    }
  }
}

.b-tile-divider {
  font-size: 10px;
  color: $grey;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: $padding-base 0 $padding-small;

  &.m-due {
    color: $pinkish-tan;
    opacity: 1;
  }

  &.m-totals {
    display: flex;
    justify-content: space-between;
  }

  .e-owed {
    color: $owed-text;
    font-weight: 700;
  }

  .e-owe {
    color: $owe-text;
    font-weight: 700;
  }

  &:first-child {
    margin-top: 0;
  }
}

.b-tile-month-divider {
  position: relative;
  display: flex;
  background-color: $white-alt;
  padding: $padding-base 0 10px;
  justify-content: space-between;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -50vw;
    right: -50vw;
    bottom: 0;
    background-color: $white-alt;
    z-index: -1;
  }

  .e-month {
    font-size: 14px;
    font-weight: 700;
  }

  .e-estimate {
    font-size: 14px;
    font-weight: 300;
    color: $grey;

    strong {
      font-size: 16px;
      font-weight: 700;
      color: initial;
    }
  }
}

.b-tile {
  margin-bottom: $padding-base;
  width: 100%;

  &.m-expandable {
    display: flex;
    cursor: pointer;
  }

  &.m-settle {
    margin-bottom: $padding-small;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .e-tile-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .e-tile-edit {
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
  }

  .e-tile-date {
    font-size: 10px;
    line-height: 1.9;
    letter-spacing: 0.5px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .e-tile-time {
    font-size: 10px;
    opacity: 0.5;
    white-space: nowrap;
    padding-left: $padding-small;
  }

  .e-tile-block {
    border-radius: 5px;
    flex-grow: 1;

    &.m-future,
    &.m-owe,
    &.m-owed,
    &.m-not-involved {
      padding: $padding-small;

      &.m-settle {
        padding: 10px $padding-small;
      }
    }

    &.m-future {
      background-color: $future;
    }

    &.m-owe {
      background-color: $owe;

      .e-user-share.m-current-user {
        color: $owe-text;
      }
    }

    &.m-owed {
      background-color: $owed;
    }

    &.m-not-involved {
      background-color: $not-involved;
    }

    &.m-activity {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.38;
    }

    &.m-settle {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .e-avatar {
    display: flex;
    align-items: center;
    max-width: calc(
      100% - 135px
    ); // Subtract actions buttons "Pay/Remind/Settled"
  }

  .e-avatar-name {
    display: block;
    padding-left: 10px;
    width: 100%;
    max-width: calc(100% - 44px); // Subtract Avatar icon
    @include text-ellipse();

    @media (min-width: $tablet-breakpoint) {
      padding-left: 22px;
    }
  }

  .e-tile-amount {
    font-size: 18px;
    text-align: right;
    border-radius: 25px;

    &.m-settle {
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      min-width: 130px;
      padding: 11px 25px;

      @media all and (min-width: $tablet-breakpoint) {
        padding: 6px 34px;
      }
    }

    &.m-owe {
      color: $white;
      background-color: $owe-text;
      cursor: pointer;
    }

    &.m-owed {
      color: $white;
      background-color: $owed-text;
      cursor: pointer;
    }

    &.m-not-involved {
      color: $not-involved-text;
      text-transform: uppercase;
      font-size: 10px;
    }
  }

  .e-transaction {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .e-tile-content {
    overflow: hidden;
    height: 0;
    opacity: 0;
    cursor: default;
  }

  .e-share {
    display: flex;
    justify-content: space-between;
    margin-top: $padding-small / 2;
  }

  .e-user {
    font-size: 10px;
    color: $grey;

    &.m-current-user {
      color: $black;
    }
  }

  .e-user-share {
    font-size: 12px;
    color: $grey;
    font-weight: 700;

    &.m-current-user {
      color: $black;
    }
  }

  .e-images {
    display: flex;
    width: 93%;
    padding: $padding-small 0 5px;
  }

  .e-image-obj {
    width: calc(25% - 10px); // 100% / Max image amount
    //height: 66px;
    margin-left: 14px;
    overflow: hidden;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    cursor: pointer;

    @media (min-width: $tablet-breakpoint) {
      width: calc(16.666666666666667% - 10px); // 100% / Max image amount
    }

    &.m-count {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey-alt-3;
      color: $white;
      font-size: 16px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .e-image {
    position: relative;
    padding-top: 100%;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .e-count-number {
    font-size: 18px;
    font-weight: 700;
  }

  .e-actions {
    display: flex;
    justify-content: space-between;
    margin: $padding-base 0 ($padding-small / 2);
  }

  .e-action-btns {
    display: flex;
  }

  .e-remove {
    padding: $padding-small;
    margin: -$padding-small;
    cursor: pointer;

    &.m-disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .e-edit {
    background-color: transparent !important;
  }

  .e-paid {
    margin-left: ($padding-small / 2);
  }

  .e-edit,
  .e-paid {
    padding: ($padding-small / 3) $padding-base;

    .e-label {
      font-size: 14px;
    }
  }

  .e-expander {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    min-width: 32px;
    max-width: 32px;
    cursor: pointer;

    &.m-disabled {
      opacity: 0.4;
    }
  }

  .e-expand {
    transition: transform ease 0.2s;

    &.m-closed {
      &.m-up,
      &.m-down {
        transform: rotate(0deg);
      }
    }

    &.m-up {
      margin-bottom: -2px;
      transform: rotate(-180deg);
    }

    &.m-down {
      margin-top: -2px;
      transform: rotate(180deg);
    }
  }

  .e-tile-type {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .e-tile-payment {
    display: flex;
    align-items: center;
    padding: ($padding-small - 3) $padding-base ($padding-small - 3)
      $padding-small;
    margin-right: 32px; // Expander width
    background-color: $grey-tile;
    border-radius: 5px;
    box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.22);
  }

  .e-payment-text {
    font-size: 12px;
    font-weight: 600;
    padding-left: $padding-small - 3;
    color: $grey;

    strong {
      color: $black;
    }
  }

  .e-tile-user-avatar {
    margin-right: $padding-small / 2;
  }

  .e-settle-action {
    display: none;
    @media all and (min-width: $tablet-breakpoint) {
      display: block;
      font-size: 11px;
      line-height: 10px;
      font-weight: 700;
      color: rgba($white, 1);
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }
  }
}
