@import "../../variables.scss";

.react-calendar {
  width: 272px;
  border-radius: 5px;
  border: none;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.13), 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: $white-alt;
  padding: 10px;

  @media all and (min-width: $mobile-small-breakpoint + 60) {
    width: 350px;
  }

  &__navigation {
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;

    button {
      background-color: $pinkish-tan;
      color: $white;
      font-size: 14px;

      &:enabled:hover,
      &:enabled:focus {
        background-color: rgba($pinkish-tan, 0.3);
      }
    }
  }

  &__tile {
    font-size: 12px;
    border-radius: 50%;
    padding: 0.8em 0.5em;
    width: 36px;
    height: 36px;
    line-height: 1.1;

    @media all and (min-width: $mobile-small-breakpoint + 60) {
      font-size: 14px;
      padding: 1em 0.5em;
      max-width: 43px !important;
      height: 43px;
    }

    &:enabled:hover {
      background-color: rgba($pinkish-tan, 0.3);
    }

    &--now {
      font-weight: 700;
    }

    &--active {
      color: $white !important;
      background-color: $pinkish-tan !important;
    }
  }

  &__month-view {
    &__weekdays__weekday {
      abbr[title] {
        text-decoration: none;
      }
    }

    &__days__day--weekend {
      color: $pinkish-tan;
    }
  }
}
