@import '../../variables.scss';

.b-group-page {
    .e-container {
        max-width: $dashboardWidth + ($padding-base * 2);
        margin: 0 auto;
        padding-left: $padding-base !important;
        padding-right: $padding-base !important;
    }

    .e-group-modal {
        text-align: center;

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 27px;
        }

        p {
            font-size: 16px;
            margin-bottom: $padding-base;

            &.m-red {
                font-weight: 700;
                color: $owe-text;
            }
        }
    }

    .e-expense-button {
        position: absolute;
        left: 50%;
        font-size: 16px;
        bottom: $padding-base;
        transform: translateX(-50%);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        padding: ($padding-base / 2) $padding-base;
        border-radius: 30px;
        z-index: 1;
        white-space: nowrap;
    }

    .e-modal-btn {
        margin: 0 auto $padding-base;
    }
}