@import '../../variables.scss';

.b-zendesk-sign-in-page {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;

  .e-img-icon {
    background-color: $pinkish-tan;
    border-radius: 50%;
  }
}