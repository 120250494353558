@import "../../variables.scss";

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.b-profile-settings,
.b-currencies-list {
  text-align: left;

  .e-settings-li-label > *:empty {
    background: linear-gradient(220deg, #f7f7f7 15%, #f5f3f2 25%, #f7f7f7 45%);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;

    border-radius: 3px;
    margin-right: 60%;
  }

  .e-settings-title {
    font-size: 12px;
    text-transform: uppercase;
    color: $grey;
    padding-bottom: $padding-small;
  }

  .e-settings-li {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    background-color: #fafafa;
    padding: 0.75rem 1.5rem;
    cursor: pointer;

    &.m-settings-li-disabled {
      color: $grey;
      cursor: auto;
    }

    &.m-settings-li-light {
      background-color: $white;
    }
  }

  &.m-currencies-input .e-settings-li {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;

    background-color: $grey-field;
    border-radius: 5px;
  }

  .e-settings-li-content {
    display: flex;
  }

  .e-settings-li-label {
    font-size: 1.125rem;
    font-weight: 600;
    flex: 1 1 auto;
    letter-spacing: -0.025rem;
    &-name {
      display: block;
      font-size: 0.75rem;
      letter-spacing: 0.015rem;
    }

    &-iso-code:empty {
      height: 1rem;
      margin-bottom: 0.125rem;
    }
    &-name:empty {
      height: 0.75rem;
      margin-right: 75%;
    }
  }

  .e-settings-li-action,
  .e-settings-li-active {
    font-size: 1.5rem;
    font-weight: 900;
    flex: 0 0 20px;
    align-self: center;
    line-height: 1;
  }

  .e-settings-li-action {
    display: flex;
  }

  .e-settings-li-active {
    display: flex;
    font-size: 0.75rem;
    font-weight: 500;
    color: $pinkish-tan;
    margin-right: 10px;
  }
}

.e-settings-currency {
  .e-settings-li,
  .e-settings-li\:input {
    margin-bottom: 1.5rem;
  }
}
.e-currencies-separator {
  margin: 0;
  color: transparent;
  border-width: 0px;
  border-top-width: 1px;
  border-color: $grey-field;
  border-style: solid;
}

.e-search-no-result {
  text-align: center;
}
