@import '../../variables.scss';
@import '../../mixins.scss';

.b-nav {
    width: 100vw;
    background-color: $white-alt;
    position: relative;
    z-index: 1;

    @media (min-width: $tablet-breakpoint) {
        width: 100%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    }

    .e-nav-container {
        display: flex;
        position: relative;
        width: 100vw;
        justify-content: space-between;
        align-items: center;
        @include box-shadow();
        z-index: 1;

        @media (min-width: $tablet-breakpoint) and (max-width: $tablet-landscape-breakpoint - 1) {
            padding: 0 $padding-small;
        }

        @media (min-width: $tablet-breakpoint) {
            width: 100%;
            margin: 0 auto;
            align-items: center;
            max-width: $dashboardWidth;
            box-shadow: none;
        }

        .e-nav-icon {
            padding: $padding-base;
            width: 24px;
            height: 24px;
            box-sizing: content-box;
            cursor: pointer;

            @media (min-width: $tablet-breakpoint) {
                &.m-menu {
                    display: none;
                }

                &.m-new-kin {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    width: auto;
                    white-space: nowrap;
                    font-size: 16px;
                    font-weight: 600;

                    img {
                        margin-right: $padding-small;
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            img {
                width: 24px;
                height: 24px;
            }
        }

        .e-nav-title {
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;

            @media (min-width: $tablet-breakpoint) {
                font-size: 18px;
                line-height: 72px;
                text-align: left;
                text-transform: initial;
            }
        }
    }
}