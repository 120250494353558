@import '../../variables.scss';

.b-kin-settings-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .e-remove-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    padding-top: $padding-small;
    cursor: pointer;

    img {
      vertical-align: middle;
      margin-left: $padding-small;
    }
  }

  .e-settings-edit {
    position: absolute;
    bottom: -$padding-small;
    right: -$padding-small;
    padding: $padding-small;
    cursor: pointer;
  }
}