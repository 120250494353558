@import "../../variables.scss";

.b-tabs {
  .e-tabs-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
  }

  .e-tab {
    position: relative;
    width: calc(100% / 2);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 64px;
    opacity: 0.4;
    transition: opacity ease 0.3s;
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    outline: 0;

    @media (min-width: $tablet-breakpoint) {
      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black;
        opacity: 0.05;
        transform: translateY(100%);
        transition: transform ease 0.3s;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 4px;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black;
      transform: translateY(400%);
      transition: transform ease 0.3s;
    }

    &:hover {
      opacity: 1;

      &:before {
        transform: translateY(0);
      }
    }

    &.m-disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }

    &.m-selected {
      opacity: 1;

      &:after {
        transform: translateY(0);
      }
    }
  }

  .e-tabs-panel {
    opacity: 0;
    transform: translateY(40px);
    transition: opacity ease 0.3s, transform ease 0.3s;

    &.m-selected {
      padding: $padding-base 0;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
