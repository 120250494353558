@import '../../variables.scss';

.b-reminder-modal {
  .e-reminder-description {
    margin: 40px auto 30px;
    font-size: 18px;
  }

  .e-reminder-title {
    font-weight: 700;
    margin-top: 50px;
    font-size: 18px;
  }

  .e-reminder-btn {
    max-width: 320px;
    width: 100%;
    padding-left: $padding-small;
    padding-right: $padding-small;
    margin: 0 auto $padding-small;

    .e-label {
      font-size: 14px;
    }
  }

  .e-reminder-cancel {
    padding: $padding-small;
    cursor: pointer;
  }
}