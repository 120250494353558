@import '../../variables.scss';

.b-explore-page {
    max-width: $dashboardWidth;
    height: 100%;

    @media (min-width: $tablet-breakpoint) {
        margin: 0 auto;
        padding-top: 54px;
    }

    .e-iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}