@import "../../variables.scss";

.b-people-page {
  max-width: $dashboardWidth + ($padding-small * 2);
  margin: 0 auto;
  padding: $padding-small $padding-small $padding-small;

  @media (min-width: $tablet-breakpoint) {
    margin: $padding-small auto 0;
  }

  .e-people-title {
    font-size: 32px;
    margin: $padding-base 0;

    @media (min-width: $tablet-breakpoint) {
      margin-top: 0;
    }
  }

  .e-kin-share {
    margin: 32px auto $padding-small;
    max-width: 400px;
    position: relative;
  }

  .e-kin-share-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    padding: 15px;
    transform: translateY(-50%);
  }

  .e-share-field {
    .e-input {
      padding-right: 60px;
    }
  }
}
