/** ---------------------------------------------
* Colours
---------------------------------------------  */

$black: #000;
$pinkish-tan: #e27c7b;
$scarlet: #d0021b;
$blush-16: rgba(243, 136, 135, 0.16);
$blue: #285b97;
$denim: #376f70;
$denim-10: rgba(55, 111, 112, 0.1);
$pale-teal: #8cbfc0;
$beige: #f3d99d;
$brown-grey: #979797;
$very-light-link: #f5f3f2;
$white-two: #ede7e7;
$white-alt: #fcfbfb;
$white: #fff;
$grey-tile: #f5f3f2;
$grey: #4a4a4a;
$grey-alt: #d8d8d8;
$grey-alt-2: #9b9b9b;
$grey-alt-3: #bdbdbd;
$grey-field: #f6f4f4;
$grey-field-highlight: #ebe6e6;

$brand-google: #db4437;
$brand-facebook: #3b5998;

$future: #dfe7f0;
$owe: #fdecec;
$owe-text: #f38887;
$owed: #d7e2e2;
$owed-text: $denim;
$not-involved: #ebeaea;
$not-involved-text: rgba(0, 0, 0, 0.3);

/** ---------------------------------------------
* Content Loader
---------------------------------------------  */

$gradientWidth: 500px;

/** ---------------------------------------------
* Layout
---------------------------------------------  */

$padding-base: 24px;
$padding-small: 16px;

$desktop-breakpoint: 960px;
$tablet-landscape-breakpoint: 1024px;
$tablet-breakpoint: 768px;
$mobile-small-breakpoint: 320px;

$headerPadding: 44px;

$headerHeightMobile: 76px;
$headerHeight: 117px;

$headerMarginBottom: 52px;

$menuWidth: 312px;
$menuWidthXS: 280px;
$kinSettingsWidth: $menuWidth;
$kinSettingsWidthXS: $menuWidthXS;

$bannerHeightMobile: 150px;
$bannerHeight: 96px;

$dashboardWidth: 624px;

/** ---------------------------------------------
* Page Modal
---------------------------------------------  */

$pageModalHeadMobile: 153px;
$pageModalHead: 136px;

/** ---------------------------------------------
* Component Z-Indexes
---------------------------------------------  */

$pageModalZIndex: 1000;
$zendeskMobileZIndex: ($pageModalZIndex)-1;
$dropdownZIndex: ($pageModalZIndex)+1;
$overlayForSettingsZIndex: ($pageModalZIndex)+1;
$kinSettingsZIndex: ($overlayForSettingsZIndex)+1;
$sidebarZIndex: ($kinSettingsZIndex)+1;
$zendeskZIndex: ($sidebarZIndex)+1;
$modalZIndex: ($sidebarZIndex)+1;
$errorZIndex: ($modalZIndex)+1;
