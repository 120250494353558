@import "../../variables.scss";

.b-avatar-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0; // Removed unwanted space below li items

    .e-avatar {
        position: relative;
        margin-left: -10px;
        display: inline-block;
        z-index: 4;

        &.m-spaced {
            margin-left: 0;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        &:not(.m-spaced).m-pushed {
            margin-left: 5px;
        }

        &:first-child {
            margin-left: 0;
            max-width: 100%;
        }
    }

    .e-avatar+.e-avatar {
        max-width: 100%;
        z-index: 3;
    }

    .e-avatar+.e-avatar+.e-avatar {
        max-width: 100%;
        z-index: 2;
    }

    .e-avatar+.e-avatar+.e-avatar+.e-avatar {
        max-width: 100%;
        z-index: 1;
    }
}