@import "../../variables.scss";

.b-profile-settings-bank {
  margin-top: $padding-base;

  .e-settings-bank {
    position: relative;
    border-radius: 5px;
    background-color: $very-light-link;
    padding: $padding-small;
  }

  &.m-editing {
    .e-settings-bank {
      background-color: $white;
    }
  }

  .e-bank-detail {
    display: block;
    margin-top: 3px;

    &:first-child {
      margin-top: 0;
    }
  }

  .e-bank-actions {
    position: absolute;
    bottom: 0;
    padding: $padding-small;
    cursor: pointer;

    &.m-copy {
      padding-right: ($padding-small) / 2;
      right: 48px;
    }

    &.m-edit {
      padding-left: ($padding-small) / 2;
      right: 0;
    }
  }
}
