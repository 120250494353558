@import '../../variables.scss';

.b-profile-setup {
    padding: 0 $padding-small;

    h1 {
        @media (min-width: $tablet-breakpoint) {
            max-width: 400px;
        }

        @media (min-width: $desktop-breakpoint) {
            max-width: 624px;
        }
    }
}