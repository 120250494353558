@import "../../variables.scss";
@import "../../mixins.scss";

.b-sidebar {
  position: fixed;
  top: 0;
  left: calc(-100vw - 2px);
  bottom: 0;
  z-index: $sidebarZIndex;
  transition: transform ease 0.3s;
  background-color: $white-alt;
  display: flex;
  @include box-shadow();

  &.m-open {
    transform: translateX(calc(100vw + 2px));
  }

  @media (min-width: $tablet-breakpoint) {
    position: relative;
    left: auto;
  }
}

.b-profile-card {
  position: relative;
  padding: ($padding-small - 4) $padding-base;
  background-color: $pale-teal;
  flex: 0 0 auto;

  .e-details {
    margin-left: $padding-small;
  }

  .e-profile-name {
    font-size: 20px;
    font-weight: 700;
    max-width: calc(#{$menuWidth} - 48px - 80px - 56px);
    @include text-ellipse;

    @media (max-width: $mobile-small-breakpoint) {
      max-width: calc(#{$menuWidthXS} - 48px - 80px - 56px);
    }
  }

  .e-profile-email {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 600;
    max-width: calc(#{$menuWidth} - 48px - 80px);
    @include text-ellipse;

    @media (max-width: $mobile-small-breakpoint) {
      max-width: calc(#{$menuWidthXS} - 48px - 80px);
    }
  }

  .e-profile-settings {
    position: absolute;
    top: 0;
    right: 0;
    padding: $padding-base;
    cursor: pointer;
  }
}

.b-menu {
  display: block;
  width: $menuWidth;
  padding: 0;
  box-sizing: border-box;

  @media (max-width: $mobile-small-breakpoint) {
    width: $menuWidthXS;
  }

  .e-menu-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: $padding-base;
    text-decoration: none;
    line-height: 64px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.06px;
    font-weight: 700;
    cursor: pointer;
    transition: opacity ease 0.3s;
    z-index: 1;

    img {
      margin-right: $padding-small;
      width: 24px;
    }

    > img {
      position: relative;
      z-index: 2;
    }

    &.m-active {
      background-color: $white-two;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background-color: $white-two;
      transform: translateX(-100%);
      transition: transform ease 0.3s;
      z-index: 1;
    }

    @media (min-width: $tablet-breakpoint) {
      &:hover {
        &:after {
          transform: translateX(0);
        }
      }
    }
  }

  .e-notification-pulse {
    position: absolute;
    top: 21px;
    left: 41px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $pinkish-tan;
    animation: pulse 1.5s ease infinite;

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(5);
        opacity: 0;
      }
    }
  }

  .e-menu-list {
    position: relative;
    max-height: calc(100vh - 470px);
    background-color: $grey-field;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      box-shadow: inset 0 2px 2px -1px rgba($grey-alt, 0.8);
      z-index: 1;
    }
  }

  .e-menu-list-item {
    position: relative;
    padding: 0 $padding-small 0 ($padding-base - 4px);
    line-height: 56px;
    height: 56px;
    display: flex;
    margin: 4px;
    align-items: center;
    text-decoration: none;
    border-radius: 7px;
    overflow: hidden;
    cursor: pointer;

    &.m-new {
      background-color: $white;
      font-weight: 600;

      &:before {
        display: none;
      }

      + .e-menu-list-item {
        &:before {
          display: none;
        }
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background-color: $grey-field-highlight;
      transform: translateX(calc(-100% - 8px));
      transition: transform ease 0.3s;
      z-index: 1;
    }

    &:hover {
      &:after {
        transform: translateX(0);
      }

      + .e-menu-list-item {
        &:before {
          display: none;
        }
      }
    }

    &.m-active {
      background-color: $grey-field-highlight;
    }
  }

  .e-menu-list-thumb {
    position: relative;
    z-index: 2;
    width: 44px;
    min-width: 44px;
    height: 44px;
    margin-right: $padding-small;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  .e-menu-item-label {
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }

  .e-menu-item-new {
    position: relative;
    padding: $padding-small / 2;
    margin: 0 $padding-small;
    z-index: 3;
  }

  .e-menu-list-name {
    position: relative;
    z-index: 2;
    @include text-ellipse;
    line-height: 56px;

    &.m-person {
      display: flex;
      flex-direction: column;
    }

    div {
      @include text-ellipse;
      line-height: initial;
    }
  }

  .e-menu-list-amount {
    font-size: 12px;
    font-weight: 300;

    span {
      font-weight: 700;

      &.m-owed {
        color: $owed-text;
      }

      &.m-owe {
        color: $owe-text;
      }
    }
  }
}

.e-sidebar-menu-top {
  flex: 1 1 auto;
}
