@import "../../variables.scss";

.b-toggle {
  border-radius: 20px;
  padding: 6px 7px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: $white-alt;
  display: flex;
  justify-content: space-evenly;

  .e-option {
    width: 50%;
    text-align: center;
    font-size: 14px;
    color: $grey;
    letter-spacing: 0.02px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px;
    cursor: pointer;
    transition: background-color ease 0.3s;
    border-radius: 14px;

    &.m-active {
      background-color: rgba($pinkish-tan, 0.3);
    }

    &.m-future {
      &.m-active {
        background-color: rgba($blue, 0.3);
      }
    }
  }
}