@import '../../variables.scss';

.b-loader {
    display: flex;

    .e-dot {
        width: 10px;
        height: 10px;
        margin: 0 2px;
        border-radius: 50%;
        background-color: $pinkish-tan;
        animation: dotAnim 1s ease infinite;

        &.m-white {
            background-color: $white;
        }

        + .e-dot {
            animation-delay: 0.3s;
        }

        @keyframes dotAnim {
            0% {
                opacity: 0;
                transform: scale(0.5);
            }
            50% {
                opacity: 1;
                transform: scale(1);
            }
            100% {
                opacity: 0;
                transform: scale(0.5);
            }
        }
    }
}